<template>
    <div class="page">
        <div class="page-title flex justify-between bg-white">
            <h1 class=" typography-h1 title">
                Žánry
            </h1>
            <q-toggle v-if="showArchivedToggle" v-model="listArchived" class="q-mx-lg my-toggle" left-label :label="$t('label.show-archived')" />
        </div>
        <div class="wrapper">
            <div class="row">
                <div class="col-4 q-pr-md discounts-wrapper">
                    <q-btn
                        v-if="!createMode|| active || viewMode"
                        @click="showCreateCard"
                        class="my-btn create-card"
                        style="width: 100%"
                        label="Přidat žánr"
                        type="text"
                        color="black"
                        icon-right="svguse:/icons.svg#plus2px"
                    />
                    <CardGenre class="skeleton" @delete="createMode=false" v-if="createMode && !active"/>
                    <div class="q-mb-md"></div>
                    <div
                        v-if="filteredGenres.length"
                        v-for="genre in filteredGenres"
                        :key="genre.id"
                        class="q-mb-md">
                        <CardGenre
                            :class="{active: active && active.id === genre.id}"
                            @click.native="setActive(genre)"
                            :id="genre.id"
                            @delete="del"
                            :title="genre.title"/>
                    </div>
                </div>
                <div class="col-8">
                    <div v-if="filteredGenres.length && !createMode && !viewMode"  class="empty">
                        <q-icon name="svguse:/icons.svg#empty" size="48px" class="block q-mb-md"/>
                        <span class="text-grey-6">{{$t('label.select-genre')}}</span>
                    </div>
                    <div v-if="!filteredGenres.length && !createMode && !viewMode"  class="empty">
                        <q-icon name="svguse:/icons.svg#empty" size="48px" class="block q-mb-md"/>
                        <span class="text-grey-6">{{$t('label.empty-genre')}}</span>
                    </div>
                    <CardGenreCreate @close="closeEdit" @create="createGenre" v-if="createMode && !viewMode && !active"/>
                    <CardGenreCreate @close="closeEdit" :content="active" @create="updateGenre" v-if="viewMode && active"/>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardGenre from "../components/Card/CardGenre/CardGenre";
import CardGenreCreate from "../components/Card/CardGenre/CardGenreCreate";
export default {
    components: {CardGenreCreate, CardGenre},
    @cancellableFetch
    async created() {
        await this.fetchAll('settings');
        await this.fetchMany('genres');
    },

    meta: {
        title: 'Žánry',
    },
    data() {
        return {
            listArchived: false,
            createMode: false,
            active: null,
            viewMode: false,
        };
    },
    computed:{
        filteredGenres() {
            return (this.genres || []).filter(g => this.listArchived ? g.archived : !g.archived).reverse();
        },
    },
    methods:{
        setActive(item) {
            this.createMode = false
            this.viewMode = true;
            this.active = null
            setTimeout(()=>{
                this.active = item
                this.viewMode = true
            }, 0)
        },
        async updateGenre(item){
            const transformed = {
                ...item.original,
                title:item.title,
            }
            const genre = await this.update('genres', transformed);
            this.updateIn(this.genres, genre);
            notifyOk(this.$t('notification.general_information-updated'));
            this.active=genre
        },
        async createGenre(item) {
            const transformed = {
                title:item.title,
            }
            const genre = await this.create('genres', transformed);
            this.genres.push(genre);
            notifyOk(this.$t('notification.genres_saved'));
            this.active=genre
            this.createMode = false
            this.viewMode = true;
        },
        async del(genre) {
            const {result, archived} = await this.delete('genres', genre);
            if (result === 'destroyed') {
                notifyOk(this.$t('notification.genres_deleted'));
                this.deleteIn(this.genres, genre);
                this.closeEdit()

            } else if (result === 'archived') {
                notifyOk("Sleva byla archivována.");
                genre.archived = archived;
                this.updateIn(this.genres, genre);
                this.closeEdit()

            } else {
                debug.warn(`Unknown deletion result: '${result}'`);
            }
        },
        closeEdit() {
            this.active = null
            this.createMode = false
            this.viewMode = false;
        },
        showCreateCard(){
            this.active = null
            this.createMode = false
            this.viewMode = false;
            this.active = null;
            setTimeout(()=>{
                this.createMode = true
            }, 0)
        },
    },
    mounted() {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    },
    beforeDestroy() {
        document.removeEventListener('keydown', (e) => {
            if (e.keyCode === 27 || e.code === 'Escape') {
                this.closeEdit();
            }
        });
    }

};
</script>

<style scoped lang="scss">
.page-title{
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid $black-200;
    padding: 24px 16px;
    align-items: center;

}
.wrapper{
    overflow: hidden;
    position: relative;
    padding: 32px 16px 0;
}
.discounts-wrapper {
    height: calc(100vh -  137px);
    overflow-y: scroll;
    -ms-overflow-style: none;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }
}
.empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 188px;
    width: 100%;
    border: 1px solid #BABABA;
}
.create-card, .skeleton{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
}
</style>
